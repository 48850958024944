'use client';

import React from 'react';
import { signOut, useSession } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import Logo from './Logo';
import Image from 'next/image';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/app/components/ui/dropdown-menu";
import { Settings, LogOut, Bell, LayoutDashboard } from 'lucide-react';
import Link from 'next/link';
import NotificationBadge from './NotificationBadge';
import { SessionUser } from '@/app/auth';

interface HeaderProps {
  title?: string;
  showSignOut?: boolean;
  userEmail?: string;
  userRole?: 'sourcer' | 'shopper' | 'shopper_guest';
  profilePictureUrl?: string | null;
}

const Header: React.FC<HeaderProps> = ({ 
  title, 
  showSignOut = true, 
  userEmail: propUserEmail, 
  userRole: propUserRole, 
  profilePictureUrl: propProfilePictureUrl 
}) => {
  const router = useRouter();
  const { data: session } = useSession();
  const sessionUser = session?.user as SessionUser | undefined;

  // Use props if provided, otherwise fall back to session data
  const userEmail = propUserEmail || sessionUser?.email;
  const profilePictureUrl = propProfilePictureUrl || sessionUser?.profilePictureUrl;
  const userRole = propUserRole || (sessionUser?.isSourcer ? 'sourcer' : sessionUser?.isShopper ? 'shopper' : undefined);

  // Check if user is admin (using the same logic as AdminProtectedLayout)
  const userId = sessionUser?.id ? parseInt(sessionUser.id) : null;
  const isAdmin = userId ? [4, 19].includes(userId) : false;

  const getLogoHref = () => {
    if (!userRole) return '/';
    
    if (userRole === 'sourcer') return '/sourcer';
    if (userRole === 'shopper' || userRole === 'shopper_guest') return '/shopper';
    
    return '/';
  };

  const handleSignOut = async () => {
    await signOut({ redirect: false });
    router.push('/login');
  };

  return (
    <header className="bg-sourced-white border-b border-sourced-black">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
        <Logo variant="black" type="full" className="h-10 w-auto" href={getLogoHref()} />
        {showSignOut && userEmail && (
          <div className="flex items-center gap-4">
            <span className="text-sm text-sourced-black">{userEmail}</span>
            <DropdownMenu>
              <DropdownMenuTrigger className="outline-none">
                <div className="h-8 w-8 rounded-full bg-gray-200 flex items-center justify-center text-gray-600 hover:bg-gray-300 transition-colors relative overflow-hidden">
                  {profilePictureUrl ? (
                    <Image 
                      src={profilePictureUrl} 
                      alt="Profile Picture" 
                      fill 
                      className="object-cover" 
                    />
                  ) : (
                    userEmail?.[0]?.toUpperCase() || '?'
                  )}
                  <NotificationBadge />
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-48">
                <Link href="/notifications">
                  <DropdownMenuItem className="cursor-pointer relative">
                    <Bell className="mr-2 h-4 w-4" />
                    <span className="relative">
                      Notifications
                    </span>
                  </DropdownMenuItem>
                </Link>
                <Link href="/settings">
                  <DropdownMenuItem className="cursor-pointer">
                    <Settings className="mr-2 h-4 w-4" />
                    Settings
                  </DropdownMenuItem>
                </Link>
                {isAdmin && (
                  <Link href="/admin">
                    <DropdownMenuItem className="cursor-pointer">
                      <LayoutDashboard className="mr-2 h-4 w-4" />
                      Admin Dashboard
                    </DropdownMenuItem>
                  </Link>
                )}
                <DropdownMenuSeparator />
                <DropdownMenuItem 
                  className="cursor-pointer text-red-600 focus:text-red-600"
                  onSelect={handleSignOut}
                >
                  <LogOut className="mr-2 h-4 w-4" />
                  Sign out
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
