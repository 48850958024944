'use client'

import { useRef, useState, useEffect } from 'react';
import { joinWaitlist } from '@/app/lib/waitlist';
import { sendGAEvent, GA_EVENTS } from '@/app/lib/analytics';

export default function WaitlistForm() {
  const formRef = useRef<HTMLFormElement>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [isError, setIsError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => setMessage(null), 5000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  async function handleSubmit(event: React.FormEvent) {
    event.preventDefault(); // Prevent the default form submission
    setIsSubmitting(true);
    const formData = new FormData(formRef.current!);
    const result = await joinWaitlist(formData);
    if (result.error) {
      setMessage(result.error);
      setIsError(true);
      setIsSubmitting(false);
    } else if (result.success) {
      setIsSuccess(true);
      setIsSubmitting(false); // Ensure to reset isSubmitting to false
      // Send GA event for successful waitlist signup
      const email = formData.get('email') as string;
      sendGAEvent({
        ...GA_EVENTS.WAITLIST.SIGNUP,
        label: email,
      });
    }
  }

  return (
    <div className="mt-6">
      {isSuccess ? (
        <p className="text-sourced-black">You have been added to the waitlist!</p>
      ) : (
        <form ref={formRef} onSubmit={handleSubmit}>
          <div className="flex items-center">
            <input
              type="email"
              name="email"
              placeholder="Enter your email"
              required
              className="px-4 py-2 border border-sourced-black focus:outline-none"
              disabled={isSubmitting}
            />
            <button
              type="submit"
              className="ml-2 px-4 py-2 bg-sourced-black text-sourced-white border border-sourced-black transition duration-300"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Join Waitlist'}
            </button>
          </div>
          {message && (
            <p className={`mt-2 ${isError ? 'text-red-600' : 'text-sourced-black'}`}>
              {message}
            </p>
          )}
        </form>
      )}
    </div>
  );
}
