'use client';

import { useEffect, useState } from 'react';
import { getUnreadNotificationCount } from '@/app/lib/notificationActions';
import { SessionUser } from '@/app/auth';
import { useSession } from 'next-auth/react';

interface NotificationBadgeProps {
  className?: string;
}

export default function NotificationBadge({ className = '' }: NotificationBadgeProps) {
  const { data: session } = useSession();
  const [unreadCount, setUnreadCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchUnreadCount() {
      if (!session?.user) return;

      try {
        const sessionUser = session.user as unknown as SessionUser;
        const result = await getUnreadNotificationCount(Number(sessionUser.id));
        setUnreadCount(result.count);
      } catch (error) {
        console.error('Error fetching unread notification count:', error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchUnreadCount();

    // Set up interval to check for new notifications every minute
    const intervalId = setInterval(fetchUnreadCount, 60000);

    return () => clearInterval(intervalId);
  }, [session]);

  if (isLoading || unreadCount === 0) return null;

  return (
    <span className={`absolute -top-1 -right-1 flex h-4 w-4 items-center justify-center rounded-full bg-sourced-pink text-[10px] font-bold text-sourced-black ${className}`}>
      {unreadCount > 9 ? '9+' : unreadCount}
    </span>
  );
}
