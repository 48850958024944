interface GAEventProps {
  action: string;
  category: string;
  label?: string;
  value?: number;
}

export const sendGAEvent = ({ action, category, label, value }: GAEventProps) => {
  if (typeof window !== 'undefined' && (window as any).gtag) {
    (window as any).gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  }
};

// Predefined events
export const GA_EVENTS = {
  WAITLIST: {
    SIGNUP: {
      action: 'signup',
      category: 'waitlist',
    },
  },
  AUTH: {
    LOGIN: {
      action: 'login',
      category: 'authentication',
    },
    LOGOUT: {
      action: 'logout',
      category: 'authentication',
    },
  },
  EMAIL: {
    UNSUBSCRIBE: {
      action: 'unsubscribe',
      category: 'email_preferences',
    },
  },
} as const;
