import Image from 'next/image';
import Link from 'next/link';

interface LogoProps {
  variant?: 'white' | 'black';
  type?: 'full' | 'icon';
  className?: string;
  href?: string;
}

export default function Logo({ variant = 'black', type = 'full', className = '', href }: LogoProps) {
  const src = type === 'full' 
    ? `/images/SourcedBy_logo_${variant}.png`
    : `/images/SourcedBy_icon_${variant}.png`;
  
  const alt = 'Sourced By Logo';
  
  const logoImage = (
    <Image
      src={src}
      alt={alt}
      width={type === 'full' ? 150 : 40}  // Adjust these values based on your logo's dimensions
      height={type === 'full' ? 50 : 40}
      className={`${className} ${href ? 'cursor-pointer' : ''}`}
    />
  );

  if (href) {
    return (
      <Link href={href}>
        {logoImage}
      </Link>
    );
  }

  return logoImage;
}
